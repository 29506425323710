const linksArray = document.querySelectorAll('[data-href]');
const originUrl = window.location.origin;

if (linksArray) {
  linksArray.forEach((link) => {
    link.addEventListener('click', (e) => {
      const dataHref = e.currentTarget.getAttribute('data-href');
      window.location.href = `${originUrl + dataHref}`;
    });
  });
}
